    /*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
    
    .p {
        &-about {}
        &-about__main {
            padding: 0 20px;
        }
        &-about__gray {
            max-width: 649.3px;
            margin: 115px auto;
            color: black;
            background-color: #E6E6E6;
            padding: 46px 45px 103px;
            @media only screen and (max-width: 767px) {
                padding: 46px 20px 103px;
            }
        }
        &-about__title {
            font-size: 2rem;
            text-align: center;
        }
        &-about__list {
            margin-top: 50px;
        }
        &-about__item {
            font-size: 1.6rem;
            border-bottom: 1px solid;
            display: -webkit-box;
            display: flex;
            padding: 15px 0;
            @media only screen and (max-width: 516px) {
                flex-wrap: wrap;
                font-size: 1.4rem;
            }
            span {
                &:first-child {
                    padding-left: 20px;
                    width: 130px;
                    @media only screen and (max-width: 516px) {
                        width: 100%;
                        padding-left: 0;
                    }
                }
                &:last-child {
                    width: 80%;
                    line-height: 2;
                    @media only screen and (max-width: 516px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    
    .c {
        &-subpage {}
        &-subpage__title {
            background-color: #242629;
            font-size: 2rem;
            height: 87.25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-subpage__bg {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 803px;
            background-attachment: fixed;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            &.about {
                background-image: url(../img/bg/bg-about.jpg);
            }
            &.coating {
                background-image: url(../img/bg/bg-coating.jpg);
                @media only screen and (max-width: 516px) {
                    height: auto;
                }
            }
            &.polishing {
                background-image: url(../img/bg/bg-polishing.jpg);
                @media only screen and (max-width: 1024px) {
                    background-attachment: initial;
                    height: 600px;
                }
                @media only screen and (max-width: 767px) {
                    height: 400px;
                }
                @media only screen and (max-width: 516px) {
                    height: 300px;
                }
            }
        }
        &-subpage__white {
            max-width: 689.3px;
            background-color: rgba(255, 255, 255, 0.815);
            color: black;
            padding: 42px 37px 46px;
            margin: 0 20px;
            @media only screen and (max-width: 516px) {
                padding: 42px 20px 46px;
            }
            h3 {
                text-align: center;
                font-size: 2rem;
            }
            p {
                font-size: 1.6rem;
                margin-top: 32px;
                line-height: 2;
                font-weight: bold;
            }
        }
    }
    
    .p {
        &-polishing__main {
            padding: 0 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            @media only screen and (max-width: 516px) {
                padding: 0 10px;
            }
        }
        &-polishing__title {
            font-size: 2.753rem;
            color: #00A9EB;
            text-align: center;
            margin: 70px 0 77px;
            @media only screen and (max-width: 767px) {
                font-size: 2rem;
            }
        }
        &-polishing__list {
            display: flex;
            margin-left: calc( var(--px1) * -1);
            flex-wrap: wrap;
            max-width: 959.16px;
            margin-bottom: 182px;
            @media only screen and (max-width: 516px) {
                margin-bottom: 100px;
            }
        }
        &-polishing__item {
            width: calc(100% / 3 - var(--px1));
            margin-left: var(--px1);
            margin-bottom: 51px;
            @media only screen and (max-width: 516px) {
                margin-bottom: 10px;
            }
        }
    }
    
     :root {
        --px1: 50px;
        @media only screen and (max-width: 767px) {
            --px1: 20px;
        }
        @media only screen and (max-width: 516px) {
            --px1: 10px;
        }
    }
    
    .coating {}
    
    .p {
        &-coating {}
        &-coating__main {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 20px;
        }
        &-coaitng__info {
            max-width: 729.16px;
            margin: 35px 0 110px;
        }
        &-coating__title {
            width: 468.73px;
            @media only screen and (max-width: 767px) {
                width: 408.73px;
            }
            @media only screen and (max-width: 516px) {
                width: auto;
                max-width: 335px;
            }
        }
        &-coating__list {
            max-width: 729.16px;
            margin-top: 52px;
        }
        &-coating__item {
            padding: 31px 56px 5px;
            background-color: #E6E6E6;
            margin-bottom: 62px;
            @media only screen and (max-width: 767px) {
                padding: 31px 20px 5px;
            }
            @media only screen and (max-width: 516px) {
                padding: 20px 20px 5px;
            }
        }
        &-coating__top {
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
            align-items: center;
        }
        &-coating__image {
            width: 44.5%;
        }
        &-coating__arrow {
            width: 14.52px;
        }
        &-coating__bottom {
            text-align: center;
            font-size: 1.8rem;
            color: black;
            margin: 29px 0 29px;
            @media only screen and (max-width: 516px) {
                margin: 20px 0 15px;
                font-size: 1.6rem;
            }
        }
    }
    
    .c {
        &-subpage__sm {
            display: none;
            @media only screen and (max-width: 516px) {
                display: block;
                margin: 80px 20px;
            }
        }
        &-subpage__container {
            max-width: 363.76px;
        }
        &-subpage__black {
            font-size: 3.218rem;
            color: white;
            background-color: black;
            height: 60.55px;
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            width: 100%;
        }
        &-subpage__gray {
            max-width: 460px;
            background-image: url(../img/subpage-gray.png);
            padding: 10px 13px 20px;
            font-size: 4.023rem;
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
            color: black;
            width: 100%;
            @media only screen and (max-width: 516px) {
                display: none;
            }
        }
        &-subpage__text {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: start;
            align-items: flex-start;
            margin-top: 20px;
            align-items: center;
            img {
                width: 62%;
                margin-left: 10px;
            }
        }
        &-subpage__info {}
        &-subpage__content {
            margin-top: 7px;
            line-height: 1.2;
            font-size: 2.146rem;
            margin-bottom: 24px;
        }
        &-subpage__image {}
        &-subpage__yellow {
            font-size: 2rem;
            color: #FFF100;
            text-align: center;
            margin-top: 16px;
        }
    }