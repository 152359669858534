@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

table,
input, textarea, select, option {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:hover img {
  opacity: 0.80;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

img {
  max-width: 100%;
}

.sp-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Layout .l-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/* Header
------------------------------------------------------------*/
.c-header__list {
  display: -webkit-box;
  display: flex;
  max-width: 1122px;
  margin: 27px auto;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .c-header__list {
    justify-content: space-between;
  }
}

.c-header__logo {
  width: 259.85px;
}

@media only screen and (max-width: 767px) {
  .c-header__logo {
    width: 230.85px;
  }
}

@media only screen and (max-width: 516px) {
  .c-header__logo {
    width: 205.85px;
  }
}

.c-header__menu {
  width: 626px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-left: 119px;
}

@media only screen and (max-width: 1024px) {
  .c-header__menu {
    margin-left: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .c-header__menu {
    display: none;
  }
}

.c-header__menu li:first-child {
  width: 87.079px;
}

.c-header__menu li:nth-child(2) {
  width: 110.165px;
}

.c-header__menu li:nth-child(3) {
  width: 74.95px;
}

.c-header__menu li:nth-child(4) {
  width: 97.66px;
}

.c-header__bottom {
  -webkit-transition: height 0.3s linear;
  transition: height 0.3s linear;
}

.c-header__bottom.is-fixed {
  position: fixed;
  width: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: black;
}

@media only screen and (max-width: 516px) {
  .c-header__bottom.is-fixed .c-header__list {
    margin: 20px auto;
  }
}

/* Main menu
------------------------------------------------------------*/
.c-header__gray {
  background-color: #242629;
}

.site-list {
  display: flex;
  justify-content: space-between;
  max-width: 1123px;
  margin: 0 auto;
  padding: 0 20px;
  align-items: center;
}

.site-text {
  font-size: 1.772rem;
  font-weight: 700;
}

.site-link {
  display: flex;
  font-size: 1.1rem;
  margin-right: -7px;
}

.site-link img {
  margin-left: 5px;
  width: 10px;
}

.p-loader {
  left: 0;
  top: 0;
  background: black;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-header__burger span {
  position: relative;
  display: block;
  width: inherit;
  height: 14.5px;
}

.c-header__burger {
  margin-left: 10px;
  display: none;
  width: 33.4px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .c-header__burger {
    display: block;
  }
}

.c-header__burger span hr {
  position: absolute;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 0.567px;
  background-color: white;
  border: none;
  border-radius: 1px;
  transition: top 0.2s 0.3s linear, opacity 0s 0.3s linear, transform 0.3s 0s linear, -webkit-transform 0.3s 0s linear;
}

.c-header__burger span hr:nth-child(1) {
  top: 0;
}

.c-header__burger span hr:nth-child(2) {
  top: 100%;
}

.c-header__burger span hr:nth-child(3) {
  top: 50%;
}

.c-header__burger.active span hr {
  transition: top 0.2s 0s linear, opacity 0s 0.2s linear, transform 0.3s 0.2s linear, -webkit-transform 0.3s 0.2s linear;
}

.c-header__burger.active span hr:nth-child(1) {
  top: 50%;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.c-header__burger.active span hr:nth-child(2) {
  top: 50%;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.c-header__burger.active span hr:nth-child(3) {
  opacity: 0;
}

.c-header__border {
  z-index: 2;
  position: relative;
  display: block;
  padding-top: 3px;
  padding-bottom: 3px;
}

.c-header__border::after {
  z-index: 999;
  content: "";
  position: absolute;
  top: 195%;
  left: 0;
  width: 100%;
  height: 3px;
  background: #1c4a9e;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.c-header__link:hover .c-header__border:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.c-header__nav {
  top: 84px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-box-align: center;
  align-items: center;
  height: 196.7px;
  display: none;
  width: 100%;
  position: absolute;
}

.c-header__container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.c-header__image {
  margin-left: 23px;
  width: 272px;
  margin-right: 45px;
  height: 160px;
  color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 2rem;
  position: relative;
  overflow: hidden;
}

.c-header__meta {
  margin-right: 100px;
}

.c-header__meta:last-child {
  margin-right: 0;
}

.c-header__image span {
  z-index: 2;
}

.c-header__subpage {
  margin-bottom: 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.c-header__subpage a {
  display: flex;
}

.c-header__line {
  width: 5px;
  margin-right: 10px;
}

.c-header__border:hover {
  cursor: pointer;
}

.c-header__link.second:hover .c-header__nav:before {
  height: 37px;
  content: "";
  position: absolute;
  top: -37px;
  left: 0;
  width: 100%;
}

.c-header__image::before {
  background-image: url(../img/nav/nav-company.jpg);
  background-size: cover;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.c-header__link.second:hover .c-header__nav {
  display: -webkit-box;
  display: flex;
}

.c-header__image:hover {
  opacity: 1;
}

.c-header__image:hover::before {
  -webkit-transform: scale(1.075);
  transform: scale(1.075);
}

.c-header .menu {
  z-index: 6;
  position: fixed;
  top: 123px;
  left: 0;
  width: 100%;
  bottom: 100%;
  color: white;
  background-color: black;
  -webkit-transition: bottom 0.5s ease;
  transition: bottom 0.5s ease;
  overflow-y: hidden;
}

.c-header .menu.is-small {
  top: 81px;
}

.c-header .menu.active {
  bottom: 0;
}

.inner {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.item:first-child {
  width: 87.079px;
}

.item:nth-child(2) {
  width: 110.165px;
}

.item:nth-child(3) {
  width: 74.95px;
}

.item:nth-child(4) {
  width: 97.66px;
}

.item a {
  position: relative;
  display: -webkit-box;
  display: flex;
  width: 100%;
  min-height: 70px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.item > dt.accordion a {
  padding-right: 40px;
}

.item > dt.accordion a:before,
.item > dt.accordion a:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 12px;
  height: 2px;
  margin-top: -1px;
  margin-left: -6px;
  background: white;
  border-radius: 1px;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  right: 44px;
}

.item > dt.accordion a:before,
.item > dt.accordion a:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 12px;
  height: 2px;
  margin-top: -1px;
  margin-left: -6px;
  background: white;
  border-radius: 1px;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  right: 44px;
}

.item > dt.accordion a:after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.item > dd {
  background: #f0f0f0;
  height: 0;
  overflow: hidden;
}

.item > dd a {
  padding-left: 35px;
  border-bottom: 1px solid;
  color: #1c4a9e;
  background-color: white;
}

.item > dd a::before {
  margin-left: -20px;
  margin-right: 20px;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  content: "";
  display: inline-block;
  border-right: 1px solid;
  border-bottom: 1px solid;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.item > dt.accordion a:before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.item > dt.accordion.active a:before,
.item > dt.accordion.active a:after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.item > dd.show {
  height: auto;
}

.item > dd ul li:last-child a {
  border-bottom: none;
}

body.active {
  overflow: hidden;
}

/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer {
  background-color: #606060;
  padding: 116px 20px 87px;
}

.c-footer__logo {
  width: 259.85px;
  margin: 0 auto;
}

@media only screen and (max-width: 516px) {
  .c-footer__logo {
    width: 220.85px;
  }
}

.c-footer__list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 89.59px;
  margin: 25px auto;
}

.c-footer__social {
  width: 38.93px;
}

.c-footer__text {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 1167.73px;
  margin: 91px auto 0;
  font-size: 1.6rem;
}

@media only screen and (max-width: 516px) {
  .c-footer__text {
    font-size: 1.4rem;
  }
}

.c-footer__sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .c-footer__sp {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__pc {
    display: none;
  }
}

.c-footer__bottom {
  display: -webkit-box;
  display: flex;
  font-size: 1.8rem;
  margin-top: 48px;
  height: 66px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 5px 0;
  background-color: black;
  width: 100%;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s transform ease-in-out;
  transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
  z-index: 5;
  border-top: 1px solid white;
}

.c-footer__bottom.is-show {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@media only screen and (max-width: 1024px) {
  .c-footer__bottom {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__bottom {
    height: 55px;
  }
}

.c-footer__item {
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 31.5%;
  color: white;
  border-right: 1px solid;
}

@media only screen and (max-width: 1024px) {
  .c-footer__item {
    width: 30.5%;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__item {
    width: 28.5%;
  }
}

@media only screen and (max-width: 767px) and (max-width: 516px) {
  .c-footer__item {
    width: 26.5;
  }
}

.c-footer__access {
  border-right: none;
}

.c-footer__access img {
  width: 37.3px;
}

@media only screen and (max-width: 516px) {
  .c-footer__access img {
    width: 27px;
  }
}

.c-footer__tel img {
  width: 196.95px;
}

@media only screen and (max-width: 767px) {
  .c-footer__tel img {
    width: 27.5px;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__tel img {
    width: 23.5px;
  }
}

.c-footer__tel span {
  font-size: 3.55rem;
  font-family: 'Yantramanav', sans-serif;
  font-weight: bold;
  margin-left: 5px;
  line-height: 1;
}

@media only screen and (max-width: 1024px) {
  .c-footer__tel span {
    font-size: 2.55rem;
  }
}

.c-footer__item span {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .c-footer__item span {
    display: none;
  }
}

.c-footer__mail img {
  width: 29.3px;
}

@media only screen and (max-width: 516px) {
  .c-footer__mail img {
    width: 26px;
  }
}

.c-footer__top img {
  width: 57%;
  max-width: 43.8px;
}

@media only screen and (max-width: 516px) {
  .c-footer__top img {
    width: 50%;
  }
}

.c-footer__top {
  display: -webkit-box;
  display: flex;
  width: 6.5%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: #302927;
  cursor: pointer;
  margin: -5px 0;
}

@media only screen and (max-width: 1024px) {
  .c-footer__top {
    width: 9%;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__top {
    width: 14.5%;
  }
}

@media only screen and (max-width: 767px) and (max-width: 516px) {
  .c-footer__top {
    width: 20.5%;
  }
}

/*------------------------------------------------------------
sidebar
------------------------------------------------------------*/
/* Breadcrumb
------------------------------------------------------------*/
/* layout
------------------------------------------------------------*/
body {
  font-family: Noto Sans JP, sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  background-color: black;
  color: white;
}

main.is-padding {
  padding-top: 51.69px;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Component .c-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
icon
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
navi
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
line
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
other
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Project .p-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.c-slide {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .c-slide {
    display: block;
  }
}

.c-slide__text {
  width: 41%;
  position: absolute;
  top: 21.5%;
  left: 12.5%;
}

@media only screen and (max-width: 1024px) {
  .c-slide__text {
    left: 5.5%;
  }
}

@media only screen and (max-width: 767px) {
  .c-slide__text {
    margin: 30px auto;
    width: 70%;
    position: relative;
    top: unset;
    left: unset;
  }
}

@media only screen and (max-width: 516px) {
  .c-slide__text {
    width: 90%;
  }
}

.c-slide__right {
  width: 58.3%;
}

@media only screen and (max-width: 767px) {
  .c-slide__right {
    width: 100%;
  }
}

.p-load {
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.p-load__star {
  width: 61%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#p-lightbox__2.show-popup {
  opacity: 1;
  visibility: visible;
}

#p-lightbox__2 {
  visibility: hidden;
}

.p-top1 {
  height: 489px;
  background-color: #242629;
}

.p-top2__title {
  width: 228.3px;
  margin: 98px auto 50px;
}

@media only screen and (max-width: 1024px) {
  .p-top2__title {
    width: 170.3px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top2__title {
    width: 152.3px;
  }
}

.p-top2__list {
  display: flex;
  flex-wrap: wrap;
}

.p-top2__item {
  width: 50%;
  position: relative;
}

@media only screen and (max-width: 516px) {
  .p-top2__item {
    width: 100%;
  }
}

.p-top2__text {
  width: 345.2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 767px) {
  .p-top2__text {
    width: 86%;
  }
}

@media only screen and (max-width: 516px) {
  .p-top2__text {
    width: 280px;
  }
}

.p-top2__image {
  overflow: hidden;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.p-top2__item:hover .p-top2__image img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 1;
}

.p-top3 {
  padding: 120px 0 134px;
  background-color: #242629;
}

.p-top3__info {
  width: 136px;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .p-top3__info {
    width: 120px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top3__info {
    width: 90px;
  }
}

.p-top3__title {
  margin-top: 12px;
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 5px;
}

@media only screen and (max-width: 516px) {
  .p-top3__title {
    font-size: 1.6rem;
  }
}

.p-top3__list {
  margin-top: 51px;
  margin-bottom: 131px;
}

@media only screen and (max-width: 516px) {
  .p-top3__list {
    margin-bottom: 80px;
  }
}

.p-top3__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-top3__date {
  font-size: 1.3rem;
}

.p-top3__link {
  width: 335.12px;
  border: 1px solid;
  height: 48.87px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 2.082rem;
}

@media only screen and (max-width: 767px) {
  .p-top3__link {
    width: 230.12px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top3__link {
    font-size: 1.8rem;
  }
}

.p-top3__link img {
  width: 25.98px;
  position: absolute;
  top: 50%;
  left: 62%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .p-top3__link img {
    left: 70%;
  }
}

.p-top4__title {
  width: 214.11px;
  margin: 72px auto 102px;
}

@media only screen and (max-width: 1024px) {
  .p-top4__title {
    width: 189.11px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4__title {
    width: 142.5px;
  }
}

.p-top4__map {
  padding: 0 20px;
  max-width: 1128.29px;
  margin: 0 auto;
  height: 417px;
}

.p-top4__list {
  font-size: 1.6rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 634.17px;
  margin: 33px auto;
  line-height: 1.8;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .p-top4__list {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4__left {
    width: 100%;
    margin-bottom: 25px;
  }
}

.p-top3__list .slick-slide {
  margin: 0 18px;
}

@media only screen and (max-width: 516px) {
  .p-top3__list .slick-slide {
    margin: 0 10px;
  }
}

.p-top3__slide {
  margin-top: 170px;
  margin-bottom: 111px;
}

@media only screen and (max-width: 516px) {
  .p-top3__slide {
    margin-top: 60px;
  }
}

.p-top3__slide .slick-track {
  display: -webkit-box;
  display: flex;
  margin: 0;
}

.p-top3__slide .slick-slide {
  height: inherit;
  margin: 0 15px;
}

@media only screen and (max-width: 767px) {
  .p-top3__slide .slick-slide {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top3__slide .slick-slide {
    margin: 0 5px;
  }
}

/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-about__main {
  padding: 0 20px;
}

.p-about__gray {
  max-width: 649.3px;
  margin: 115px auto;
  color: black;
  background-color: #E6E6E6;
  padding: 46px 45px 103px;
}

@media only screen and (max-width: 767px) {
  .p-about__gray {
    padding: 46px 20px 103px;
  }
}

.p-about__title {
  font-size: 2rem;
  text-align: center;
}

.p-about__list {
  margin-top: 50px;
}

.p-about__item {
  font-size: 1.6rem;
  border-bottom: 1px solid;
  display: -webkit-box;
  display: flex;
  padding: 15px 0;
}

@media only screen and (max-width: 516px) {
  .p-about__item {
    flex-wrap: wrap;
    font-size: 1.4rem;
  }
}

.p-about__item span:first-child {
  padding-left: 20px;
  width: 130px;
}

@media only screen and (max-width: 516px) {
  .p-about__item span:first-child {
    width: 100%;
    padding-left: 0;
  }
}

.p-about__item span:last-child {
  width: 80%;
  line-height: 2;
}

@media only screen and (max-width: 516px) {
  .p-about__item span:last-child {
    width: 100%;
  }
}

.c-subpage__title {
  background-color: #242629;
  font-size: 2rem;
  height: 87.25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-subpage__bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 803px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-subpage__bg.about {
  background-image: url(../img/bg/bg-about.jpg);
}

.c-subpage__bg.coating {
  background-image: url(../img/bg/bg-coating.jpg);
}

@media only screen and (max-width: 516px) {
  .c-subpage__bg.coating {
    height: auto;
  }
}

.c-subpage__bg.polishing {
  background-image: url(../img/bg/bg-polishing.jpg);
}

@media only screen and (max-width: 1024px) {
  .c-subpage__bg.polishing {
    background-attachment: initial;
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .c-subpage__bg.polishing {
    height: 400px;
  }
}

@media only screen and (max-width: 516px) {
  .c-subpage__bg.polishing {
    height: 300px;
  }
}

.c-subpage__white {
  max-width: 689.3px;
  background-color: rgba(255, 255, 255, 0.815);
  color: black;
  padding: 42px 37px 46px;
  margin: 0 20px;
}

@media only screen and (max-width: 516px) {
  .c-subpage__white {
    padding: 42px 20px 46px;
  }
}

.c-subpage__white h3 {
  text-align: center;
  font-size: 2rem;
}

.c-subpage__white p {
  font-size: 1.6rem;
  margin-top: 32px;
  line-height: 2;
  font-weight: bold;
}

.p-polishing__main {
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 516px) {
  .p-polishing__main {
    padding: 0 10px;
  }
}

.p-polishing__title {
  font-size: 2.753rem;
  color: #00A9EB;
  text-align: center;
  margin: 70px 0 77px;
}

@media only screen and (max-width: 767px) {
  .p-polishing__title {
    font-size: 2rem;
  }
}

.p-polishing__list {
  display: flex;
  margin-left: calc( var(--px1) * -1);
  flex-wrap: wrap;
  max-width: 959.16px;
  margin-bottom: 182px;
}

@media only screen and (max-width: 516px) {
  .p-polishing__list {
    margin-bottom: 100px;
  }
}

.p-polishing__item {
  width: calc(100% / 3 - var(--px1));
  margin-left: var(--px1);
  margin-bottom: 51px;
}

@media only screen and (max-width: 516px) {
  .p-polishing__item {
    margin-bottom: 10px;
  }
}

:root {
  --px1: 50px;
}

@media only screen and (max-width: 767px) {
  :root {
    --px1: 20px;
  }
}

@media only screen and (max-width: 516px) {
  :root {
    --px1: 10px;
  }
}

.p-coating__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.p-coaitng__info {
  max-width: 729.16px;
  margin: 35px 0 110px;
}

.p-coating__title {
  width: 468.73px;
}

@media only screen and (max-width: 767px) {
  .p-coating__title {
    width: 408.73px;
  }
}

@media only screen and (max-width: 516px) {
  .p-coating__title {
    width: auto;
    max-width: 335px;
  }
}

.p-coating__list {
  max-width: 729.16px;
  margin-top: 52px;
}

.p-coating__item {
  padding: 31px 56px 5px;
  background-color: #E6E6E6;
  margin-bottom: 62px;
}

@media only screen and (max-width: 767px) {
  .p-coating__item {
    padding: 31px 20px 5px;
  }
}

@media only screen and (max-width: 516px) {
  .p-coating__item {
    padding: 20px 20px 5px;
  }
}

.p-coating__top {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  align-items: center;
}

.p-coating__image {
  width: 44.5%;
}

.p-coating__arrow {
  width: 14.52px;
}

.p-coating__bottom {
  text-align: center;
  font-size: 1.8rem;
  color: black;
  margin: 29px 0 29px;
}

@media only screen and (max-width: 516px) {
  .p-coating__bottom {
    margin: 20px 0 15px;
    font-size: 1.6rem;
  }
}

.c-subpage__sm {
  display: none;
}

@media only screen and (max-width: 516px) {
  .c-subpage__sm {
    display: block;
    margin: 80px 20px;
  }
}

.c-subpage__container {
  max-width: 363.76px;
}

.c-subpage__black {
  font-size: 3.218rem;
  color: white;
  background-color: black;
  height: 60.55px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.c-subpage__gray {
  max-width: 460px;
  background-image: url(../img/subpage-gray.png);
  padding: 10px 13px 20px;
  font-size: 4.023rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  color: black;
  width: 100%;
}

@media only screen and (max-width: 516px) {
  .c-subpage__gray {
    display: none;
  }
}

.c-subpage__text {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  align-items: flex-start;
  margin-top: 20px;
  align-items: center;
}

.c-subpage__text img {
  width: 62%;
  margin-left: 10px;
}

.c-subpage__content {
  margin-top: 7px;
  line-height: 1.2;
  font-size: 2.146rem;
  margin-bottom: 24px;
}

.c-subpage__yellow {
  font-size: 2rem;
  color: #FFF100;
  text-align: center;
  margin-top: 16px;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Utility .u-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
