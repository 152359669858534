/* Header
------------------------------------------------------------*/

.c {
    &-header__list {
        display: -webkit-box;
        display: flex;
        max-width: 1122px;
        margin: 27px auto;
        -webkit-box-align: center;
        align-items: center;
        padding: 0 20px;
        @media only screen and (max-width: 767px) {
            justify-content: space-between;
        }
    }
    &-header__logo {
        width: 259.85px;
        @media only screen and (max-width: 767px) {
            width: 230.85px;
        }
        @media only screen and (max-width: 516px) {
            width: 205.85px;
        }
    }
    &-header__menu {
        width: 626px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-left: 119px;
        @media only screen and (max-width: 1024px) {
            margin-left: 70px;
        }
        @media only screen and (max-width: 767px) {
            display: none;
        }
        li {
            &:first-child {
                width: 87.079px;
            }
            &:nth-child(2) {
                width: 110.165px;
            }
            &:nth-child(3) {
                width: 74.95px;
            }
            &:nth-child(4) {
                width: 97.66px;
            }
        }
    }
}

.c-header__bottom {
    -webkit-transition: height 0.3s linear;
    transition: height 0.3s linear;
}

.c-header__bottom.is-fixed {
    position: fixed;
    width: 100%;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: black;
    .c-header__list {
        @media only screen and (max-width: 516px) {
            margin: 20px auto;
        }
    }
}


/* Main menu
------------------------------------------------------------*/

.c-header__gray {
    background-color: #242629;
}

.site-list {
    display: flex;
    justify-content: space-between;
    max-width: 1123px;
    margin: 0 auto;
    padding: 0 20px;
    align-items: center;
}

.site-text {
    font-size: 1.772rem;
    font-weight: 700;
}

.site-link {
    display: flex;
    font-size: 1.1rem;
    margin-right: -7px;
}

.site-link img {
    margin-left: 5px;
    width: 10px;
}

.p-loader {
    left: 0;
    top: 0;
    background: black;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.c-header__burger span {
    position: relative;
    display: block;
    width: inherit;
    height: 14.5px;
}

.c-header__burger {
    margin-left: 10px;
    display: none;
    width: 33.4px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    @media only screen and (max-width: 767px) {
        display: block;
    }
}

.c-header__burger span hr {
    position: absolute;
    margin: 0 auto;
    display: block;
    width: 100%;
    height: 0.567px;
    background-color: white;
    border: none;
    border-radius: 1px;
    transition: top 0.2s 0.3s linear, opacity 0s 0.3s linear, transform 0.3s 0s linear, -webkit-transform 0.3s 0s linear;
}

.c-header__burger span hr:nth-child(1) {
    top: 0;
}

.c-header__burger span hr:nth-child(2) {
    top: 100%;
}

.c-header__burger span hr:nth-child(3) {
    top: 50%;
}

.c-header__burger.active span hr {
    transition: top 0.2s 0s linear, opacity 0s 0.2s linear, transform 0.3s 0.2s linear, -webkit-transform 0.3s 0.2s linear;
}

.c-header__burger.active span hr:nth-child(1) {
    top: 50%;
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

.c-header__burger.active span hr:nth-child(2) {
    top: 50%;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.c-header__burger.active span hr:nth-child(3) {
    opacity: 0;
}

.c-header__border {
    z-index: 2;
    position: relative;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
}

.c-header__border::after {
    z-index: 999;
    content: "";
    position: absolute;
    top: 195%;
    left: 0;
    width: 100%;
    height: 3px;
    background: #1c4a9e;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.c-header__link:hover .c-header__border:after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.c-header__nav {
    top: 84px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-box-align: center;
    align-items: center;
    height: 196.7px;
    display: none;
    width: 100%;
    position: absolute;
}

.c-header__container {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
}

.c-header__image {
    margin-left: 23px;
    width: 272px;
    margin-right: 45px;
    height: 160px;
    color: white;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 2rem;
    position: relative;
    overflow: hidden;
}

.c-header__meta {
    margin-right: 100px;
    &:last-child {
        margin-right: 0;
    }
}

.c-header__image span {
    z-index: 2;
}

.c-header__subpage {
    margin-bottom: 15px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.c-header__subpage a {
    display: flex;
}

.c-header__line {
    width: 5px;
    margin-right: 10px;
}

.c-header__border:hover {
    cursor: pointer;
}

.c-header__link.second:hover .c-header__nav:before {
    height: 37px;
    content: "";
    position: absolute;
    top: -37px;
    left: 0;
    width: 100%;
}

.c-header__image::before {
    background-image: url(../img/nav/nav-company.jpg);
    background-size: cover;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.c-header__link.second:hover .c-header__nav {
    display: -webkit-box;
    display: flex;
}

.c-header__image:hover {
    opacity: 1;
}

.c-header__image:hover::before {
    -webkit-transform: scale(1.075);
    transform: scale(1.075);
}

.c-header .menu {
    z-index: 6;
    position: fixed;
    top: 123px;
    left: 0;
    width: 100%;
    bottom: 100%;
    color: white;
    background-color: black;
    -webkit-transition: bottom 0.5s ease;
    transition: bottom 0.5s ease;
    overflow-y: hidden;
    &.is-small {
        top: 81px;
    }
}

.c-header .menu.active {
    bottom: 0;
}

.inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.list {}

.item {
    &:first-child {
        width: 87.079px;
    }
    &:nth-child(2) {
        width: 110.165px;
    }
    &:nth-child(3) {
        width: 74.95px;
    }
    &:nth-child(4) {
        width: 97.66px;
    }
}

.item a {
    position: relative;
    display: -webkit-box;
    display: flex;
    width: 100%;
    min-height: 70px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.item>dt.accordion a {
    padding-right: 40px;
}

.item>dt.accordion a:before,
.item>dt.accordion a:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 12px;
    height: 2px;
    margin-top: -1px;
    margin-left: -6px;
    background: white;
    border-radius: 1px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    right: 44px;
}

.item>dt.accordion a:before,
.item>dt.accordion a:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 12px;
    height: 2px;
    margin-top: -1px;
    margin-left: -6px;
    background: white;
    border-radius: 1px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    right: 44px;
}

.item>dt.accordion a:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.item>dd {
    background: #f0f0f0;
    height: 0;
    overflow: hidden;
}

.item>dd a {
    padding-left: 35px;
    border-bottom: 1px solid;
    color: #1c4a9e;
    background-color: white;
}

.item>dd a::before {
    margin-left: -20px;
    margin-right: 20px;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    content: "";
    display: inline-block;
    border-right: 1px solid;
    border-bottom: 1px solid;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.item>dt.accordion a:before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.item>dt.accordion.active a:before,
.item>dt.accordion.active a:after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.item>dd.show {
    height: auto;
}

.item>dd ul li:last-child a {
    border-bottom: none;
}

body.active {
    overflow: hidden;
}