/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p-top {
    //-------------------------------------------------------
    &1 {}
    //-------------------------------------------------------
    &2 {}
    //-------------------------------------------------------
    &3 {}
    //-------------------------------------------------------
    &4 {}
}

.c {
    &-slide {
        display: flex;
        justify-content: flex-end;
        position: relative;
        @media only screen and (max-width: 767px) {
            display: block;
        }
    }
    &-slide__text {
        width: 41%;
        position: absolute;
        top: 21.5%;
        left: 12.5%;
        @media only screen and (max-width: 1024px) {
            left: 5.5%;
        }
        @media only screen and (max-width: 767px) {
            margin: 30px auto;
            width: 70%;
            position: relative;
            top: unset;
            left: unset;
        }
        @media only screen and (max-width: 516px) {
            width: 90%;
        }
    }
    &-slide__right {
        width: 58.3%;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &-slide__car {}
}

.p {
    &-load {
        background-color: black;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
    }
    &-load__star {
        width: 61%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

#p-lightbox__2.show-popup {
    opacity: 1;
    visibility: visible;
}

#p-lightbox__2 {
    visibility: hidden;
}

.p {
    &-top1 {
        height: 489px;
        background-color: #242629;
    }
    &-top2 {}
    &-top2__title {
        width: 228.3px;
        margin: 98px auto 50px;
        @media only screen and (max-width: 1024px) {
            width: 170.3px;
        }
        @media only screen and (max-width: 516px) {
            width: 152.3px;
        }
    }
    &-top2__list {
        display: flex;
        flex-wrap: wrap;
    }
    &-top2__item {
        width: 50%;
        position: relative;
        @media only screen and (max-width: 516px) {
            width: 100%;
        }
    }
    &-top2__text {
        width: 345.2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media only screen and (max-width: 767px) {
            width: 86%;
        }
        @media only screen and (max-width: 516px) {
            width: 280px;
        }
    }
}

.p-top2__image {
    overflow: hidden;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.p-top2__item:hover .p-top2__image img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
}

.p {
    &-top3 {
        padding: 120px 0 134px;
        background-color: #242629;
    }
    &-top3__info {
        width: 136px;
        margin: 0 auto;
        @media only screen and (max-width: 1024px) {
            width: 120px;
        }
        @media only screen and (max-width: 516px) {
            width: 90px;
        }
    }
    &-top3__title {
        margin-top: 12px;
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 5px;
        @media only screen and (max-width: 516px) {
            font-size: 1.6rem;
        }
    }
    &-top3__list {
        margin-top: 51px;
        margin-bottom: 131px;
        @media only screen and (max-width: 516px) {
            margin-bottom: 80px;
        }
    }
    &-top3__item {}
    &-top3__image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-top3__date {
        font-size: 1.3rem;
    }
    &-top3__link {
        width: 335.12px;
        border: 1px solid;
        height: 48.87px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-size: 2.082rem;
        @media only screen and (max-width: 767px) {
            width: 230.12px;
        }
        @media only screen and (max-width: 516px) {
            font-size: 1.8rem;
        }
        img {
            width: 25.98px;
            position: absolute;
            top: 50%;
            left: 62%;
            transform: translateY(-50%);
            @media only screen and (max-width: 767px) {
                left: 70%;
            }
        }
    }
    &-top4 {}
    &-top4__title {
        width: 214.11px;
        margin: 72px auto 102px;
        @media only screen and (max-width: 1024px) {
            width: 189.11px;
        }
        @media only screen and (max-width: 516px) {
            width: 142.5px;
        }
    }
    &-top4__map {
        padding: 0 20px;
        max-width: 1128.29px;
        margin: 0 auto;
        height: 417px;
    }
    &-top4__list {
        font-size: 1.6rem;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 634.17px;
        margin: 33px auto;
        line-height: 1.8;
        flex-wrap: wrap;
        @media only screen and (max-width: 767px) {
            padding: 0 20px;
        }
    }
    &-top4__left {
        @media only screen and (max-width: 516px) {
            width: 100%;
            margin-bottom: 25px;
        }
    }
    &-top4__right {}
}

.p-top3__list .slick-slide {
    margin: 0 18px;
    @media only screen and (max-width: 516px) {
        margin: 0 10px;
    }
}

.p-top3__slide {
    margin-top: 170px;
    margin-bottom: 111px;
}

@media only screen and (max-width: 516px) {
    .p-top3__slide {
        margin-top: 60px;
    }
}

.p-top3__slide .slick-track {
    display: -webkit-box;
    display: flex;
    margin: 0;
}

.p-top3__slide .slick-slide {
    height: inherit;
    margin: 0 15px;
    @media only screen and (max-width: 767px) {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 516px) {
    .p-top3__slide .slick-slide {
        margin: 0 5px;
    }
}