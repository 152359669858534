/* layout
------------------------------------------------------------*/

body {
    font-family: Noto Sans JP, sans-serif;
    font-size: 1.4rem;
    line-height: 1.5;
    background-color: black;
    color: white;
}

//-------------------------------------------------------
main {
    &.is-padding {
        padding-top: 51.69px;
    }
}

section {}

//-------------------------------------------------------
.l-container {}

//-------------------------------------------------------
.c-mainvisual {}