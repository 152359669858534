/*------------------------------------------------------------
footer
------------------------------------------------------------*/

.c {
    &-footer {
        background-color: #606060;
        padding: 116px 20px 87px;
    }
    &-footer__logo {
        width: 259.85px;
        margin: 0 auto;
        @media only screen and (max-width: 516px) {
            width: 220.85px;
        }
    }
    &-footer__list {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 89.59px;
        margin: 25px auto;
    }
    &-footer__social {
        width: 38.93px;
    }
    &-footer__text {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 1167.73px;
        margin: 91px auto 0;
        font-size: 1.6rem;
        @media only screen and (max-width: 516px) {
            font-size: 1.4rem;
        }
    }
}

.c-footer__sp {
    display: none;
}

@media only screen and (max-width: 767px) {
    .c-footer__sp {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .c-footer__pc {
        display: none;
    }
}

.c-footer__bottom {
    display: -webkit-box;
    display: flex;
    font-size: 1.8rem;
    margin-top: 48px;
    height: 66px;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 5px 0;
    background-color: black;
    width: 100%;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s transform ease-in-out;
    transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
    z-index: 5;
    border-top: 1px solid white;
}

.c-footer__bottom.is-show {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

@media only screen and (max-width: 1024px) {
    .c-footer__bottom {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 516px) {
    .c-footer__bottom {
        height: 55px;
    }
}

.c-footer__item {
    background-repeat: no-repeat;
    background-size: cover;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 31.5%;
    color: white;
    border-right: 1px solid;
}

@media only screen and (max-width: 1024px) {
    .c-footer__item {
        width: 30.5%;
    }
}

@media only screen and (max-width: 767px) {
    .c-footer__item {
        width: 28.5%;
        @media only screen and (max-width: 516px) {
            width: 26.5;
        }
    }
}

.c-footer__access {
    border-right: none;
}

.c-footer__access img {
    width: 37.3px;
}

@media only screen and (max-width: 516px) {
    .c-footer__access img {
        width: 27px;
    }
}

.c-footer__tel img {
    width: 196.95px;
}

@media only screen and (max-width: 767px) {
    .c-footer__tel img {
        width: 27.5px;
    }
}

@media only screen and (max-width: 516px) {
    .c-footer__tel img {
        width: 23.5px;
    }
}

.c-footer__tel span {
    font-size: 3.55rem;
    font-family: 'Yantramanav', sans-serif;
    font-weight: bold;
    margin-left: 5px;
    line-height: 1;
}

@media only screen and (max-width: 1024px) {
    .c-footer__tel span {
        font-size: 2.55rem;
    }
}

.c-footer__item span {
    margin-left: 10px;
}

@media only screen and (max-width: 767px) {
    .c-footer__item span {
        display: none;
    }
}

.c-footer__mail img {
    width: 29.3px;
}

@media only screen and (max-width: 516px) {
    .c-footer__mail img {
        width: 26px;
    }
}

.c-footer__top img {
    width: 57%;
    max-width: 43.8px;
    @media only screen and (max-width: 516px) {
        width: 50%;
    }
}

.c-footer__top {
    display: -webkit-box;
    display: flex;
    width: 6.5%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: #302927;
    cursor: pointer;
    margin: -5px 0;
}

@media only screen and (max-width: 1024px) {
    .c-footer__top {
        width: 9%;
    }
}

@media only screen and (max-width: 767px) {
    .c-footer__top {
        width: 14.5%;
        @media only screen and (max-width: 516px) {
            width: 20.5%;
        }
    }
}